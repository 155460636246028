import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Locality from '../components/Masters/Locality/Locality';

function LocalityModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateLocality' element={<Layout children={Locality()} />} />
                < Route path='/EditLocality' element={<Layout children={Locality()} />} />
                < Route path='/Locality' element={<Layout children={Locality()} />} />
            </Routes>
        </div>
    )
}
export default LocalityModule