import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import Highlight from "../components/Highlight/Highlight";

function HighlightModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/Highlight" element={<Layout children={Highlight()} />} />
        <Route
          path="/CreateHighlight"
          element={<Layout children={Highlight()} />}
        />
        <Route
          path="/EditHighlight"
          element={<Layout children={Highlight()} />}
        />
      </Routes>
    </div>
  );
}

export default HighlightModule;
